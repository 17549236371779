<template>
  <div class="auth">
    <hb-dialog size="xs" class="left-upper">
      <hb-dialog-header :allow-close="false">
        <span class="left-title">{{ $t('profile.new_welcome_text') }}</span>
        <div class="upper-header-table">
          <div class="table-row">
            <div class="table-element">
              <nuxt-icon name="local-expert-guidance" filled></nuxt-icon>
              <p v-html="$t('profile.local_expert_guidance')"></p>
            </div>
            <div class="table-element">
              <nuxt-icon name="off-market-deals" filled></nuxt-icon>
              <p v-html="$t('profile.off_market_deals')"></p>
            </div>
          </div>
          <div class="table-row">
            <div class="table-element">
              <nuxt-icon name="lowest-prices" filled></nuxt-icon>
              <p v-html="$t('profile.lowest_prices')"></p>
            </div>
            <div class="table-element">
              <nuxt-icon name="ai-agent" filled></nuxt-icon>
              <p v-html="$t('profile.ai_agent')"></p>
            </div>
          </div>
        </div>
      </hb-dialog-header>
    </hb-dialog>
    <hb-dialog size="xs" class="right-lower">
      <!-- <hb-dialog-header :after-close="afterClose" :allow-close="allowClose">
        <span v-if="!showForgotPasswordForm">{{ $t('auth.title') }}</span>
        <span v-else>{{ $t('auth.forgot_your_password') }}</span>
      </hb-dialog-header> -->

      <hb-dialog-body>
        <hb-tabs
          v-show="!showForgotPasswordForm"
          :initial-active-index="initSignUp ? 1 : 0"
        >
          <hb-tab-navs>
            <hb-tab-nav>{{ $t('profile.log_in') }}</hb-tab-nav>
            <hb-tab-nav theme="secondary">
              {{ $t('profile.sign_up') }}
            </hb-tab-nav>
          </hb-tab-navs>
          <hb-tabs-body>
            <hb-tab>
              <info-box v-if="signInInfo" inline>
                {{ signInInfo }}
              </info-box>
              <sign-in-form @success="onAuthSuccess()" />
              <div class="auth-bottom-text">
                <a href @click.prevent="showForgotPasswordForm = true">
                  {{ $t('auth.forgot_your_password') }}
                </a>
              </div>
            </hb-tab>
            <hb-tab>
              <sign-up-form
                :referral-code="referralCode"
                @success="onAuthSuccess()"
              />
            </hb-tab>
          </hb-tabs-body>
        </hb-tabs>
        <div v-show="showForgotPasswordForm">
          <forgot-password-form @success="onPasswordResetSuccess()" />
          <div class="auth-bottom-text">
            <a href @click.prevent="showForgotPasswordForm = false">
              {{ $t('auth.sign_in_with_your_email') }}
            </a>
          </div>
        </div>
      </hb-dialog-body>
      <social-auth-card
        class="auth__social-connect"
        :title="$t('profile.or_connect_with')"
        @authenticated="onAuthSuccess()"
      />
    </hb-dialog>

    <!-- <social-auth-card
      class="auth__social-connect"
      :title="$t('profile.or_connect_with')"
      @authenticated="onAuthSuccess()"
    /> -->
  </div>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue'
import AddPhoneNumberDialog from './AddPhoneNumberDialog.vue'
import HbDialogBody from '~/components/base/dialog/HbDialogBody.vue'
import HbDialog from '~/components/base/dialog/HbDialog.vue'
import HbDialogHeader from '~/components/base/dialog/HbDialogHeader.vue'
import HbTabs from '~/components/base/tab/HbTabs.vue'
import HbTabNavs from '~/components/base/tab/HbTabNavs.vue'
import HbTab from '~/components/base/tab/HbTab.vue'
import { useDialog, useDM } from '~/composables'
import SignInForm from '~/modules/auth/components/forms/SignInForm.vue'
import SignUpForm from '~/modules/auth/components/forms/SignUpForm.vue'
import HbTabsBody from '~/components/base/tab/HbTabsBody.vue'
import ForgotPasswordForm from '~/modules/auth/components/forms/ForgotPasswordForm.vue'
import SocialAuthCard from '~/modules/auth/components/SocialAuthCard.vue'
import { DialogMixin } from '~/mixins'
import InfoBox from '~/components/InfoBox.vue'
import HbTabNav from '~/components/base/tab/HbTabNav.vue'
import { useUserStore, useI18n } from '#imports'
import SuccessDialog from '~~/src/components/dialogs/SuccessDialog.vue'

export default defineComponent({
  name: 'LoginPage',
  components: {
    HbTabNav,
    InfoBox,
    SocialAuthCard,
    ForgotPasswordForm,
    HbTabsBody,
    SignUpForm,
    SignInForm,
    HbDialogHeader,
    HbDialogBody,
    HbDialog,
    HbTab,
    HbTabs,
    HbTabNavs,
  },
  mixins: [DialogMixin],
  props: {
    initSignUp: { type: Boolean, default: () => false },
    referralCode: { type: String, default: null },
    signInInfo: { type: String, default: () => null },
    allowClose: { type: Boolean, default: () => true },
    trackingPageTitle: { type: String, default: () => null },
    trackingPagePath: { type: String, default: () => null },
  },
  setup(props, ctx) {
    const dialog = useDialog(props)
    const showForgotPasswordForm = ref(false)
    const userStore = useUserStore()
    const { t } = useI18n();

    const onPasswordResetSuccess = () => {
      dialog.value.close()
    }
    const onAuthSuccess = () => {
      console.log(userStore.user)

      dialog.value.close(() => {
        props.afterClose(true)
        const { open: openDialog } = useDM()
        if (userStore.user && !userStore.user.phone) {
          openDialog(AddPhoneNumberDialog, {
            allowClose: false,
          })
        }
        openDialog(SuccessDialog, {
          title: t("registration-success.title"),
          message: t("registration-success.message"),
          hasTracking: true,
          pageTitle: props?.trackingPageTitle ?? document?.title,
          pagePath: props?.trackingPagePath ?? window?.location?.pathname,
        })
      })
    }

    return {
      dialog,
      showForgotPasswordForm,
      onPasswordResetSuccess,
      onAuthSuccess,
    }
  },
})
</script>

<style scoped lang="scss">
.auth-bottom-text {
  margin-top: 24px;
  text-align: center;
  font-size: 14px;
  font-weight: 500;

  a {
    color: var(--hb-gray4);
    text-decoration: none;
  }
}

.auth {
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: stretch;
  justify-content: center;
  //gap: 10px;

  .left-upper {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    box-shadow: none;
    border-right: solid #80808030 1px;

    .left-title {
      font-size: 36px;
    }
  }

  .right-lower {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    box-shadow: none;
  }

  &__social-connect {
    max-width: 480px;
    padding: 40px 0px 40px 0px;
  }

  .hb-dialog__header {
    //padding-bottom: 0;
    padding: 50px;
  }

  .hb-dialog__body {
    //padding-bottom: 0;
    padding: 35px 35px 35px 35px;
  }

  .hb-tab {
    padding-top: 25px;
  }
}

.upper-header-table {
  padding-top: 80px;
}

.table-element {
  padding-top: 15px;
  padding-bottom: 15px;
  font-size: 21px;
  display: flex;
  align-items: center;

  p {
    padding-left: 25px;
  }
}

.nuxt-icon {
  font-size: 35px;
}

.mobile-break {
  display: none;
}

@include mobile {

  .auth {
    flex-direction: column;
    gap: 10px;
    align-items: center;

    .left-upper, .right-lower {
      border-radius: 16px;

      .left-title {
        font-size: 28px;
      }
    }

    .hb-dialog__body {
    //padding-bottom: 0;
    padding: 35px 35px 35px 35px;
    }

    .mobile-break {
      display: block;
    }
  }

  .upper-header-table {
    display: flex;
    justify-content: space-around;
    padding: 25px 0 25px 0;
    flex-direction: column;

    .table-row {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      //gap: 30px;
    }

    .table-element {
      p {
        width: 130px;
        font-size: 18px;
      }
    }
  }

  .nuxt-icon {
    font-size: 40px;
  }
}

@media(max-width: 420px) {
  .upper-header-table {
    .table-element {
      p {
        width: 100px;
        font-size: 18px;
        padding-left: 10px;
      }
    }
  }
}

@media(max-width: 350px) {
  .upper-header-table {
    .table-row {
      flex-direction: column;
    }
  }
}

</style>
