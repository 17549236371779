<template>
  <div
    class="hb-tab-navs"
    :class="{
      'hb-tab-navs--secondary': secondary,
      'hb-tab-navs--fixed-width': fixedWidth,
      [`hb-tab-navs--${underlineStyle.theme}`]: true,
    }"
  >
    <hb-raw-btn
      v-if="showLeftScrollBtn"
      class="hb-tab-navs__scroll-btn hb-tab-navs__scroll-btn--left"
      @click="innerScroll('left')"
    >
      <nuxt-icon name="chevron-left" filled></nuxt-icon>
    </hb-raw-btn>

    <ul
      ref="navsInnerEl"
      class="hb-tab-navs__inner"
      :class="{ 'hb-tab-navs__inner--loaded': firstTransitionExecuted }"
      @transitionend="onTransitionEnd"
    >
      <slot></slot>
    </ul>

    <hb-raw-btn
      v-if="showRightScrollBtn"
      class="hb-tab-navs__scroll-btn hb-tab-navs__scroll-btn--right"
      @click="innerScroll('right')"
    >
      <nuxt-icon name="chevron-right" filled></nuxt-icon>
    </hb-raw-btn>
  </div>
</template>

<script lang="ts">
import { useTabNavs } from 'wue'
import { computed, ref } from 'vue'
import { useScroll } from '@vueuse/core'
import HbRawBtn from '~/components/base/utils/HbRawBtn.vue'

export default {
  name: 'HbTabNavs',
  components: { HbRawBtn },
  props: {
    secondary: { type: Boolean, default: false },
    fixedWidth: { type: Boolean, default: false },
    useScrollBtn: { type: Boolean, default: false },
  },
  setup(props, ctx) {
    const navsInnerEl = ref<HTMLElement>(null)
    const tabNavsCtx = useTabNavs(props, ctx)
    const { x } = useScroll(navsInnerEl)
    const firstTransitionExecuted = ref(false)
    const showLeftScrollBtn = computed<boolean>(() => {
      if (!navsInnerEl.value || !props.useScrollBtn) return false

      return x.value > 0
    })
    const showRightScrollBtn = computed<boolean>(() => {
      if (!navsInnerEl.value || !props.useScrollBtn) return false

      const scrollWidth = navsInnerEl.value.scrollWidth
      const clientWidth = navsInnerEl.value.clientWidth
      const limit = scrollWidth - clientWidth

      return limit !== x.value
    })
    const innerScroll = (direction: 'left' | 'right') => {
      const scrollDistance = 100
      navsInnerEl.value?.scrollBy?.({
        left: direction === 'left' ? -scrollDistance : scrollDistance,
        behavior: 'smooth',
      })
    }
    const onTransitionEnd = () => {
      firstTransitionExecuted.value = true
    }

    return {
      navsInnerEl,
      showLeftScrollBtn,
      showRightScrollBtn,
      firstTransitionExecuted,
      innerScroll,
      onTransitionEnd,
      ...tabNavsCtx,
    }
  },
}
</script>

<style lang="scss">
.hb-tab-navs {
  --hb-tab-nav--width: v-bind('underlineStyle.width');
  --hb-tab-nav--offset-x: v-bind('underlineStyle.left');

  position: relative;
  border-bottom: 1px var(--hb-gray2) solid;

  &__inner {
    position: relative;
    display: flex;
    align-items: stretch;
    padding: 0;

    &:after {
      --hb-tab-nav--sum: calc(
        var(--hb-tab-nav--offset-x) + var(--hb-tab-nav--width) / 2 - 50%
      );
      position: absolute;
      content: '';
      display: inline-block;
      width: var(--hb-tab-nav--width);
      height: 4px;
      background-color: var(--hb-primary);
      border-radius: 4px;
      bottom: -2px;
      transition: width 0.3s linear, background-color 0.3s linear;
      will-change: transform;
      transform: translateX(var(--hb-tab-nav--sum));

      @include tablet {
        bottom: 0;
        left: 0;
      }
    }

    @include tablet {
      overflow-x: auto;

      &::-webkit-scrollbar {
        display: none;
      }
    }

    &--loaded {
      &:after {
        transition: transform 0.3s linear, width 0.3s linear,
          background-color 0.3s linear;
      }
    }
  }

  li {
    list-style: none;
    flex: 1;
    text-align: center;
    padding: 16px 12px;
    font-weight: 700;
    cursor: pointer;
    color: var(--hb-gray4);
    display: flex;
    align-items: center;
    justify-content: center;

    @include tablet {
      white-space: nowrap;
    }
  }

  .hb-tab-nav--active {
    color: var(--hb-blue1);

    span {
      background-color: var(--hb-primary);
    }
  }

  .hb-tab-nav--disabled {
    opacity: 0.6;
    pointer-events: none;
    touch-action: none;
    user-select: none;
    user-focus: none;
  }

  &--primary {
    border-bottom: 1px var(--hb-gray2) solid;

    .hb-tab-navs__inner {
      &:after {
        background-color: var(--hb-primary);
      }
    }
  }

  &--secondary {
    border-bottom: 1px var(--hb-gray4) solid;

    .hb-tab-navs__inner {
      &:after {
        background-color: var(--hb-secondary);
      }
    }
  }

  &--fixed-width {
    li {
      flex: none;
      padding-right: 80px;
      padding-left: 80px;

      @include mobile {
        padding-right: 20px;
        padding-left: 20px;
        flex: 1;
      }
    }
  }

  &__badge {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    margin-left: 10px;
    background-color: #aab8be;
    width: 30px;
    height: 30px;
    color: #fff;
    font-size: 14px;
    font-weight: 600;
    pointer-events: none;
  }

  &__badge-left {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    margin-right: 10px;
    width: 35px;
    height: 35px;
    border: 3px solid var(--hb-gray4);
    font-size: 14px;
    font-weight: 600;
  }

  &__scroll-btn {
    display: none;
    position: absolute;
    bottom: 4px;
    font-size: 11px !important;
    height: 58px;
    z-index: 1;

    &,
    path {
      color: var(--hb-gray1) !important;
      fill: var(--hb-gray1) !important;
    }

    &--left {
      left: 0;
      text-align: left;
      padding: 0 24px 0 22px;
      background: transparent
        linear-gradient(90deg, #fff 80%, rgba(255, 255, 255, 0) 100%) 0 0
        no-repeat padding-box !important;
    }

    &--right {
      right: 0;
      text-align: right;
      padding: 0 22px 0 24px;
      background: transparent
        linear-gradient(270deg, #fff 80%, rgba(255, 255, 255, 0) 100%) 0 0
        no-repeat padding-box !important;
    }

    @include tablet {
      display: block;
    }
  }

  @include tablet {
    border: 0;
    box-shadow: inset 0 1px 0 2px white, inset 0px -5px 1px -3px var(--hb-gray2);
  }
}
</style>
