<template>
  <hb-form
    v-model="model"
    class="sign-up-form"
    :disabled="signUpPending"
    :errors="signUpError?.errors"
  >
    <hb-input name="first_name" :placeholder="$t('profile.first_name')" size="lg" />
    <hb-input name="last_name" :placeholder="$t('profile.last_name')" size="lg" />
    <hb-input name="email" :placeholder="$t('profile.email')" size="lg" />
    <hb-input name="phone" :placeholder="$t('profile.phone')" size="lg" />
    <hb-password
      name="password"
      :placeholder="$t('label.create_password')"
      size="lg"
    />
    <!-- <hb-password
      name="password_confirmation"
      :placeholder="$t('label.password_confirm')"
      size="lg"
    /> -->
    <!-- <p class="sign-up-form__text sign-up-form__text--divider">
      <strong>{{ $t('register.if_you_have') }}</strong>
    </p> -->
    <!-- <hb-input
      name="referral_code"
      :placeholder="$t('register.referral_code')"
      size="lg"
      :disabled="!!referralCode"
    /> -->
    <hb-btn
      class="sign-up-form__btn"
      size="block-lg"
      theme="secondary"
      :loading="signUpPending"
      @click="signUp()"
    >
      <span>{{ $t('label.submit') }}</span>
    </hb-btn>
    <p class="sign-up-form__text">
      {{ $t('register.by_submitting') }}
      <nuxt-link to="/terms-conditions">
        <strong>{{ $t('register.terms_conditions') }}</strong>
      </nuxt-link>
    </p>
  </hb-form>
</template>

<script lang="ts">
import type { Register } from '@homebourse/api-client'
import { useMoment } from 'wue'
import { ref } from 'vue'
import HbForm from '~/components/base/form/HbForm.vue'
import HbInput from '~/components/base/form/HbInput.vue'
import HbBtn from '~/components/base/utils/HbBtn.vue'
import { useApiHandler } from '~/composables'
import { useAuthStore } from '~/stores'
import HbPassword from '~/components/base/form/HbPassword.vue'

export default {
  name: 'SignUpForm',
  components: { HbPassword, HbBtn, HbInput, HbForm },
  props: {
    referralCode: { type: String, default: null },
  },
  emits: ['success'],
  setup(props, ctx) {
    const model = ref<Register>({
      first_name: null,
      last_name: null,
      email: null,
      phone: null,
      password: null,
      password_confirmation: null,
      timezone: useMoment.tz.guess(),
      referral_code: props.referralCode,
    })
    const {
      execute,
      pending: signUpPending,
      errorBody: signUpError,
    } = useApiHandler(() => {
      return useAuthStore().signUp(model.value)
    })
    const emitSuccess = () => ctx.emit('success')
    const signUp = () => {
      execute(() => {
        emitSuccess()
      })
    }

    return {
      model,
      signUpError,
      signUpPending,
      signUp,
    }
  },
}
</script>

<style scoped lang="scss">
.sign-up-form {
  &__text {
    font-size: 14px;
    text-align: center;
    color: var(--hb-gray4);
    font-weight: 500;

    a {
      color: inherit;
    }

    &--divider {
      margin-top: 4px;
      margin-bottom: 2px;
    }
  }

  &__btn {
    margin-top: 10px;
    margin-bottom: 9px;
  }
}
</style>
