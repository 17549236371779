<template>
  <div class="social-auth-card" :class="{ 'block-floating-loader': isPending }">
    <div v-if="title" class="social-auth-card__title"><span>{{ title }}</span></div>
    <div class="social-auth-card__box-wrap">
      <a
        v-if="facebookLoginEnabled"
        href="#"
        class="social-auth-card__box"
        @click.prevent.stop="connect(providers.Facebook)"
      >
        <nuxt-icon name="facebook-full" filled></nuxt-icon>
      </a>
      <a
        href="#"
        class="social-auth-card__box"
        @click.prevent.stop="connect(providers.Google)"
      >
        <nuxt-icon name="google-color" filled></nuxt-icon>
      </a>
      <!--<a-->
      <!--  href="#"-->
      <!--  class="social-auth-card__box"-->
      <!--  @click.prevent.stop="connect(providers.Apple)"-->
      <!--&gt;-->
      <!--  <nuxt-icon name="apple" filled></nuxt-icon>-->
      <!--</a>-->
    </div>
  </div>
</template>

<script lang="ts">
import { ref } from 'vue'
import { useRuntimeConfig } from '#app'
import { isTrue } from 'wue'
import type { SocialCallbackData } from '~/types'
import { SocialProviders } from '~/types'
import { useAuthStore, useUserStore } from '~/stores'
import { useDM } from '~/composables'
import FailureDialog from '~/components/dialogs/FailureDialog.vue'

export default {
  name: 'SocialAuthCard',
  props: {
    title: { type: String, default: () => null },
  },
  emits: ['authenticated'],
  setup(props, ctx) {
    const providers = ref(SocialProviders)
    const authStore = useAuthStore()
    const userStore = useUserStore()
    const currentProvider = ref<SocialProviders>(null)
    const config = useRuntimeConfig()
    const facebookLoginEnabled = ref(isTrue(config.public.facebookLoginEnabled))
    const isPending = ref(false)
    const emitAuthenticated = () => ctx.emit('authenticated')
    const openWindow = (url: string) => {
      const windowWidth = 500
      const windowHeight = 700
      const windowLeft = window.innerWidth / 2 - windowWidth / 2
      const windowTop = window.outerHeight / 2 - windowHeight / 2
      const authWindow = window.open(
        'about:blank',
        '_blank',
        `width=${windowWidth}px,height=${windowHeight}px,left=${windowLeft}px,top=${windowTop}px,location=no,resizable=no,toolbar=no`
      )
      const onMessage = (ev: MessageEvent<SocialCallbackData>) => {
        if (ev.data?.isSocialAuth) {
          authWindow.close()
          window.removeEventListener('message', onMessage, false)

          if (ev.data.success) {
            userStore.getMe().then(() => emitAuthenticated())
          } else if (ev.data.message) {
            useDM().open(FailureDialog, { message: ev.data.message })
          }
        }
      }

      /**
       * Safari doesn't support window.open with another domain
       */
      setTimeout(() => (authWindow.location.href = url), 250)
      window.addEventListener('message', onMessage, false)

      return authWindow
    }
    const login = () => {
      isPending.value = true
      /**
       * I can't use async function here, because safari doesn't support window.open in async function
       */
      authStore.api
        .csrfToken()
        .then(() =>
          authStore.api
            .socialLogin({ provider: currentProvider.value })
            .then((data) => openWindow(data.url))
            .catch((error) =>
              useDM().open(FailureDialog, { message: error.message })
            )
            .finally(() => (isPending.value = false))
        )
        .catch(() => (isPending.value = false))
    }
    const connect = (provider: SocialProviders) => {
      currentProvider.value = provider
      login()
    }

    return {
      providers,
      isPending,
      facebookLoginEnabled,
      connect,
    }
  },
}
</script>

<style scoped lang="scss">
.social-auth-card {
  padding: 40px;
  background: var(--hb-white);
  border-radius: 16px;
  width: 100%;

  &__title {
    text-align: center;
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 25px;
    letter-spacing: -0.3px;
    border-bottom: solid #80808030 1px;
    line-height: 0.1em;

    span {
      background:#fff; 
      padding:0 10px;
    }
  }

  &__box-wrap {
    display: flex;
    gap: 11px;
    padding: 0 30px;
  }

  &__box {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 19px;
    border: 3px solid var(--hb-gray2);
    background-color: #fff;
    border-radius: 10px;
    cursor: pointer;
    transition: 0.3s all;

    &:hover {
      background-color: var(--hb-gray2);
    }

    .nuxt-icon {
      font-size: 31px;
    }
  }

  @include mobile {
    padding: 25px;

    &__title {
      margin-bottom: 30px;
    }

    &__box {
      padding: 13px;

      .nuxt-icon {
        font-size: 25px;
      }
    }
  }
}

.auth__social-connect {
    padding-top: 0;
  }
</style>
