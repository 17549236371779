<template>
  <hb-transition :overflowed="isOverflowed">
    <div
      v-if="isActive()"
      :key="uuid"
      class="hb-tab"
      v-bind="{ ...tabBinding, ...$attrs }"
    >
      <slot></slot>
    </div>
  </hb-transition>
</template>

<script lang="ts">
import { TabMixin, useTab } from 'wue'
import HbTransition from '~/components/base/utils/HbTransition.vue'

export default {
  name: 'HbTab',
  components: { HbTransition },
  mixins: [TabMixin],
  inheritAttrs: false,
  setup(props, ctx) {
    const tabCtx = useTab(props, ctx)

    return {
      ...tabCtx,
    }
  },
}
</script>

<style lang="scss">
.hb-tab {
  width: 100%;
}

.hb-tab-navs + .hb-tabs-body {
  .hb-tab {
    padding: 16px 0;
  }
}
</style>
