<template>
  <hb-form
    v-model="model"
    class="forgot-password-form"
    :disabled="pending"
    :errors="errorBody?.errors"
  >
    <p class="forgot-password-form__text">
      {{ $t('user.forgot_password_email') }}
    </p>
    <hb-input name="email" :placeholder="$t('profile.email')" size="lg" />
    <hb-btn
      class="forgot-password-form__btn"
      theme="secondary"
      size="block-lg"
      :loading="pending"
      @click="resetPassword()"
    >
      <span>{{ $t('label.send') }}</span>
    </hb-btn>
  </hb-form>
</template>

<script lang="ts">
import type { ResetPassword } from '@homebourse/api-client'
import { ref } from 'vue'
import HbForm from '~/components/base/form/HbForm.vue'
import HbInput from '~/components/base/form/HbInput.vue'
import HbBtn from '~/components/base/utils/HbBtn.vue'
import { useApiHandler, useDM, useToasts } from '~/composables'
import { useAuthStore } from '~/stores'
import SuccessDialog from '~/components/dialogs/SuccessDialog.vue'
import { useI18n } from '#imports'

export default {
  name: 'ForgotPasswordForm',
  components: { HbBtn, HbInput, HbForm },
  emits: ['success'],
  setup(props, ctx) {
    const model = ref<ResetPassword>({
      email: null,
    })
    const authStore = useAuthStore()
    const { t } = useI18n()
    const { execute, pending, errorBody } = useApiHandler(() => {
      return authStore.resetPassword(model.value)
    })
    const emitSuccess = () => ctx.emit('success')
    const resetPassword = () => {
      execute(
        () => {
          useDM().open(SuccessDialog, {
            message: t('user.password_reset_request_successful'),
            afterClose() {
              emitSuccess()
            },
          })
        },
        () => {
          useToasts().show({
            text:
              errorBody.value.message ??
              t('user.password_reset_request_failed'),
          })
        }
      )
    }

    return {
      model,
      errorBody,
      pending,
      resetPassword,
    }
  },
}
</script>

<style scoped lang="scss">
.forgot-password-form {
  &__text {
    font-weight: 500;
    font-size: 14px;
    color: var(--hb-gray4);
    margin-bottom: 15px;
    text-decoration: none;
  }

  &__btn {
    margin-top: 10px;
  }
}
</style>
