<template>
  <div
    class="info-box info-box--caution"
    :class="{ 'info-box--inline': inline, [`info-box--${size}`]: true }"
    :style="{ margin: noMargin ? '0' : null }"
  >
    <div class="info-box__title-wrap">
      <nuxt-icon name="info" filled></nuxt-icon>
      <div class="info-box__title">
        <slot name="title"></slot>
      </div>
    </div>
    <div class="info-box__text">
      <slot></slot>
    </div>
  </div>
</template>

<script lang="ts">
export default {
  name: 'InfoBox',
  props: {
    inline: { type: Boolean, default: () => false },
    noMargin: { type: Boolean, default: () => false },
    size: { type: String as () => 'xs' | 'sm' | 'md', default: () => 'md' },
  },
}
</script>

<style lang="scss">
.info-box {
  background-color: var(--hb-gray2);
  padding: 20px 30px;
  border-radius: 10px;
  margin-bottom: 40px;

  &__title-wrap {
    display: flex;
    align-items: center;
  }

  .nuxt-icon {
    font-size: 30px;
    margin-right: 24px;
  }

  &--caution {
    .nuxt-icon {
      * {
        color: transparent;
        stroke: var(--hb-primary) !important;
      }
    }
  }

  &__title {
    font-size: 16px;
    line-height: 22px;
    font-weight: 600;
    text-transform: uppercase;
  }

  &__text {
    margin-left: 54px;
    font-size: 16px;
    line-height: 22px;
    font-weight: 500;
  }

  @include mobile {
    padding: 20px;
    margin-bottom: 20px;

    &__text {
      margin-left: 0;
    }
  }

  &--xs,
  &--sm {
    padding: 10px 15px;

    .info-box__text {
      font-size: 14px;
      margin-left: 42px;
    }

    .nuxt-icon {
      font-size: 26px;
      margin-right: 16px;
    }

    @include mobile {
      padding: 8px 10px;

      &__text {
        margin-top: 8px;
        margin-left: 0;
      }
    }
  }

  &--xs {
    .info-box__text {
      font-size: 10px;
      line-height: 15px;
    }
  }

  &--inline {
    display: flex;
    align-items: center;

    .info-box__text {
      margin-left: 0;
    }
  }
}
</style>
