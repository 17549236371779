<template>
  <div class="hb-tabs">
    <slot></slot>
  </div>
</template>

<script lang="ts">
import type { TabsProps } from 'wue'
import { TabsMixin, useTabs } from 'wue'

export default {
  name: 'HbTabs',
  mixins: [TabsMixin],
  props: {
    activeNavClass: { type: String, default: () => 'hb-tab-nav--active' },
    disabledNavClass: { type: String, default: () => 'hb-tab-nav--disabled' },
    activeTabClass: { type: String, default: () => 'hb-tab--active' },
  },
  setup(props: TabsProps, ctx) {
    const tabsCtx = useTabs(props, ctx)

    return {
      ...tabsCtx,
    }
  },
}
</script>

<style lang="scss">
.hb-tabs {
}

.v-enter-active,
.v-leave-active {
  transition: opacity 0.5s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}

.v-enter-from,
.v-enter-active,
.v-enter-to,
.v-leave-from,
.v-leave-active,
.v-leave-to {
  position: absolute;
}
</style>
