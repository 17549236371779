<template>
  <div :class="rootClasses">
    <slot></slot>
  </div>
</template>

<script lang="ts">
import { computed } from 'vue'

export default {
  name: 'HbBtnWrap',
  props: {
    vertical: { type: Boolean, default: () => false },
  },
  setup(props) {
    const rootClasses = computed(() => ({
      'button-wrapper': true,
      'button-wrapper--vertical': props.vertical,
    }))

    return {
      rootClasses,
    }
  },
}
</script>

<style lang="scss">
.button-wrapper {
  display: flex;
  align-items: center;
  gap: 0 20px;

  &--vertical {
    flex-direction: column;
    gap: 20px;
  }

  @include mobile {
    flex-direction: column;
    gap: 15px;

    .hb-btn {
      display: block;
      width: 100%;
    }
  }
}
</style>
