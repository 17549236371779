<template>
  <li
    v-bind="tabNavs.getNavBinding(tab, index)"
    v-on="tabNavs.getNavListeners(tab, index)"
  >
    <div v-if="leftItems !== null" class="hb-tab-navs__badge-left">
      {{ leftItems }}
    </div>
    <slot></slot>
    <span v-if="items" class="hb-tab-navs__badge">{{ items }}</span>
  </li>
</template>

<script lang="ts">
import { useTabNav } from 'wue/src/composable/useTabNav'
import { TabNavMixin } from 'wue/src/mixins/tab-nav.mixin'

export default {
  name: 'HbTabNav',
  mixins: [TabNavMixin],
  setup(props, ctx) {
    const tabNavCtx = useTabNav(props, ctx)

    return {
      ...tabNavCtx,
    }
  },
}
</script>

<style scoped></style>
