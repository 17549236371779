<template>
  <div class="add-phone-number">
    <hb-dialog size="sm">
      <hb-dialog-header :allow-close="allowClose">
        <span v-if="!success">{{ $t('user.add_phone_number') }}</span>
        <span v-else>{{ $t('user.phone_number_added_title') }}</span>
      </hb-dialog-header>

      <hb-dialog-body v-if="!success">
        <hb-form :disabled="pending" :errors="errorBody?.errors" no-style>
          <div class="form-group">
            <div class="form-group__inner">
              <div>
                <hb-input
                  v-model="model.phone_number"
                  name="phone_number"
                  :placeholder="$t('user.phone_number')"
                  size="lg"
                />
              </div>
            </div>
          </div>
        </hb-form>
      </hb-dialog-body>

      <!-- <hb-dialog-body v-else>
        <div class="success-message">
          {{ $t('user.phone_number_added_message') }}
        </div>
      </hb-dialog-body> -->

      <hb-dialog-footer>
        <hb-btn-wrap>
          <hb-btn
            v-if="!success"
            theme="primary"
            class="add-phone-number__btn"
            :loading="pending"
            :disabled="pending"
            @click="addPhoneNumber"
          >
            {{ $t('label.save') }}
          </hb-btn>
          <!-- <hb-btn
            v-else
            theme="secondary"
            class="add-phone-number__btn"
            @click="closeDialog"
          >
            {{ $t('label.close') }}
          </hb-btn> -->
        </hb-btn-wrap>
      </hb-dialog-footer>
    </hb-dialog>
  </div>
</template>

<script lang="ts">
import { ref, watch } from 'vue'
import HbDialog from '~/components/base/dialog/HbDialog.vue'
import HbDialogHeader from '~/components/base/dialog/HbDialogHeader.vue'
import HbDialogBody from '~/components/base/dialog/HbDialogBody.vue'
import HbDialogFooter from '~/components/base/dialog/HbDialogFooter.vue'
import HbBtnWrap from '~/components/base/utils/HbBtnWrap.vue'
import HbBtn from '~/components/base/utils/HbBtn.vue'
import HbForm from '~/components/base/form/HbForm.vue'
import HbInput from '~/components/base/form/HbInput.vue'
import { DialogMixin } from '~/mixins'
import { useDialog } from '~/composables'
import { useUserStore, useApiHandler } from '#imports'

export default {
  name: 'AddPhoneNumberDialog',
  components: {
    HbDialog,
    HbDialogHeader,
    HbDialogBody,
    HbDialogFooter,
    HbBtnWrap,
    HbBtn,
    HbForm,
    HbInput,
  },
  mixins: [DialogMixin],
  props: {
    allowClose: { type: Boolean, default: () => false },
  },
  setup(props) {
    const dialog = useDialog(props)
    const userStore = useUserStore()

    const closeDialog = (next?: () => void) => {
      dialog.value.close(() => {
        next?.()
      })
    }

    const model = ref({
      phone_number: '',
    })

    const { execute, pending, errorBody } = useApiHandler(() =>
      userStore.api.changePhone({ phone: model.value.phone_number })
    )

    const success = ref(false)

    const addPhoneNumber = () => {
      // phone number not empty
      if (!model.value.phone_number) {
        errorBody.value = {
          errors: { phone_number: ['Phone number is required'] },
        }
        return
      }

      // phone number begin with '+' or digit, after only contain digits
      const phoneRegex = /^[+\d]\d*$/
      if (!phoneRegex.test(model.value.phone_number)) {
        errorBody.value = {
          errors: { phone_number: ['Invalid phone number'] },
        }
        return
      }

      // phone number at least 5 digits
      if (model.value.phone_number.length < 5) {
        errorBody.value = {
          errors: { phone_number: ['Invalid phone number'] },
        }
        return
      }

      execute(
        () => {
          userStore.getMe()
          success.value = true
        },
        (error) => {
          if (!error?.hasFormError) {
            console.log('error:', error)
          }
        }
      )
    }

    watch(
      model,
      () => {
        errorBody.value = null
      },
      { deep: true }
    )

    return {
      model,
      errorBody,
      pending,
      success,
      addPhoneNumber,
      dialog,
      closeDialog,
    }
  },
}
</script>

<style lang="scss">
.add-phone-number {
  .add-phone-number__btn {
    padding: 12px 24px;
    margin: 0 auto;
  }
}
</style>
