<template>
  <div class="hb-tabs-body">
    <slot></slot>
  </div>
</template>

<script lang="ts">
export default {
  name: 'HbTabsBody',
}
</script>

<style scoped lang="scss">
.hb-tabs-body {
  position: relative;
}
</style>
