<template>
  <hb-form
    v-model="model"
    class="sign-in-form"
    :disabled="signInPending"
    :errors="signInError?.errors"
  >
    <hb-input name="email" placeholder="Email" size="lg" />
    <hb-password
      name="password"
      :placeholder="$t('label.password')"
      size="lg"
    />
    <hb-btn
      class="sign-in-form__btn"
      size="block-lg"
      :loading="signInPending"
      @click="signIn()"
    >
      <span>{{ $t("profile.log_in") }}</span>
    </hb-btn>
  </hb-form>
</template>

<script lang="ts">
import type { Login } from "@homebourse/api-client";
import { ref } from "vue";
import HbForm from "~/components/base/form/HbForm.vue";
import HbInput from "~/components/base/form/HbInput.vue";
import HbBtn from "~/components/base/utils/HbBtn.vue";
import { useApiHandler } from "~/composables";
import { useAuthStore } from "~/stores";
import HbPassword from "~/components/base/form/HbPassword.vue";

export default {
  name: "SignInForm",
  components: { HbPassword, HbBtn, HbInput, HbForm },
  emits: ["success"],
  setup(props, ctx) {
    const model = ref<Login>({
      email: null,
      password: null,
    });
    const {
      execute,
      pending: signInPending,
      errorBody: signInError,
    } = useApiHandler(async () => {
      return await useAuthStore().signIn(model.value);
    });
    const emitSuccess = () => ctx.emit("success");
    const signIn = () => {
      execute(() => {
        emitSuccess();
      });
    };

    return {
      model,
      signInError,
      signInPending,
      signIn,
    };
  },
};
</script>

<style scoped lang="scss">
.sign-in-form {
  &__btn {
    margin-top: 10px;
  }
}
</style>
